export default {
    translations: {
        you: 'Sie',
        chat: {
            headline: 'Nachrichten',
            encryptionFailed: 'Entschlüsselung fehlgeschlagen',
            header: '{{names}} und Sie',
            noOtherUsers: 'Noch keine Gesprächspartner',
            newMessage: 'Neue Nachricht',
            sendMessage: 'Nachricht senden',
            uploadFile: 'Datei hochladen',
            userJoined: '<i>{{name}}</i> ist dem Chat beigetreten',
            userLeft: '<i>{{name}}</i> hat den Chat verlassen',
            typing_one: '<i>{{name}}</i> tippt gerade',
            typing_other: '<i>{{names}}</i> und <i>{{name}}</i> tippen gerade'
        },
        signature: {
            requestSignature: 'Signatur beantragen',
        },
        videoControl: {
            enter: 'Beitreten',
            accept: 'Annehmen',
            decline: 'Ablehnen',
            hangUp: 'Auflegen',
            exit: 'Beenden',
            screenShare: {
                enable: 'Bildschirm freigeben',
                disable: 'Bildschirmfreigabe beenden'
            },
            camera: {
                enable: 'Kamera aktivieren',
                disable: 'Kamera deaktivieren'
            },
            mic: {
                enable: 'Mikrofon aktivieren',
                disable: 'Mikrofon deaktivieren'
            },
            chat: {
                show: 'Chat anzeigen',
                hide: 'Chat verbergen'
            }
        },
        lobby: {
            button: {
                video: {
                    enable: 'Video einschalten',
                    disable: 'Video ausschalten',
                },
                microphone: {
                    enable: 'Mikrofon einschalten',
                    disable: 'Mikrofon ausschalten',
                },
                settings: 'Geräteeinstellungen',
            },
            loading: 'Laden',
            camera: 'Kamera',
            microphone: 'Mikrofon',
            yourName: 'Ihr Name',
            enterNow: 'Jetzt beitreten',
            leave: 'Verlassen',
            noPermission: 'Keine Berechtigung',
        }
    }
}
