import React, {useEffect, useReducer, useState} from 'react'
import {shortenFileName} from "../lib";
import FilePreview from "./general/FilePreview";

type FileQueueEntry = {
    file: File,
    dataUri: string,
    loaded: boolean
}

type FileList = {
    [hash: string]: FileQueueEntry
}

type Props = {
    files: File[]
    onLoading: (loading: boolean) => void
    onChange: (files: FileQueueEntry[]) => void
    clearAction: (cb: () => void) => void
}

enum EntryKind {
    New = 'NEW',
    Loaded = 'LOADED',
    Remove = 'REMOVE',
    Clear = 'CLEAR',
}

type NewActionPayload = {
    hash: string,
    entry: FileQueueEntry
}

type LoadedActionPayload = {
    hash: string,
    dataUri: string
}

type RemoveActionPayload = {
    hash: string,
}

type Action = {
    type: EntryKind,
    payload: NewActionPayload | LoadedActionPayload | RemoveActionPayload
}

function hashCode(str: string) {
    return str.split('').reduce((prevHash, currVal) =>
        (((prevHash << 5) - prevHash) + currVal.charCodeAt(0))|0, 0);
}


const entriesReducer = (state: FileList, action: Action): FileList => {
    const { type, payload } = action

    let newState = { ...state }

    switch (type) {
        case EntryKind.New:
            // @ts-ignore
            newState[payload.hash] = payload.entry
            break
        case EntryKind.Loaded:
            newState[payload.hash].loaded = true
            // @ts-ignore
            newState[payload.hash].dataUri = payload.dataUri
            break
        case EntryKind.Remove:
            const tmp = { ...newState }
            newState = {}
            for (const h in tmp) {
                if (h !== payload.hash) {
                    newState[h] = tmp[h]
                }
            }
            break
        case EntryKind.Clear:
            newState = {}
            break
    }

    return newState
}

const FileQueue = ({ files, onLoading, onChange, clearAction }: Props) => {
    const [ loading, setLoading ] = useState<boolean>(false)
    const [ entries, dispatch ] = useReducer(entriesReducer, {})

    clearAction(() => {
        dispatch({
            type: EntryKind.Clear,
            // @ts-ignore
            payload: {}
        })
    })

    useEffect(() => {
        const hashed: { [hash: string]: File } = {}

        for (let file of Array.from(files)) {
            const hash = hashCode(file.name + file.size)
            hashed[hash] = file
        }

        for (const hash in hashed) {
            // @ts-ignore
            if (typeof entries[hash] === 'undefined') {
                const file = hashed[hash];

                const reader = new FileReader()
                reader.addEventListener('load', () => {
                    dispatch({
                        type: EntryKind.Loaded,
                        payload: {
                            hash,
                            // @ts-ignore
                            dataUri: reader.result
                        }
                    })
                }, false)

                reader.readAsDataURL(file)

                dispatch({
                    type: EntryKind.New,
                    payload: {
                        hash,
                        entry: {
                            file: hashed[hash],
                            dataUri: '',
                            loaded: false
                        }
                    }
                })
            }
        }
    }, [ files ])

    useEffect(() => {
        onChange(Object.values(entries))
    }, [ entries ])

    useEffect(() => {
        onLoading(loading)
    }, [ loading ])

    const removeFile = (hash: string) => {
        dispatch({
            type: EntryKind.Remove,
            // @ts-ignore
            payload: {
                hash
            }
        })
    }

    if (!Object.keys(entries).length) {
        if (loading) {
            setLoading(false)
        }

        return <></>
    }

    const hasLoading = Object.keys(entries)
        .map(hash => entries[hash])
        .filter(entry => !entry.loaded)
        .length > 0

    if (loading !== hasLoading) {
        setLoading(hasLoading)
    }

    return (
        <div className="upload-queue">
            <div className="inner">
                <ul>
                    { Object.keys(entries).map((hash: string) => (
                        <li key={ hash }>
                            <FilePreview
                                loading={ !entries[hash].loaded }
                                name={entries[hash].file.name}
                                data={{ type: entries[hash].file.type, dataUri: entries[hash].dataUri }}>
                                <a href="#" className="delete" onClick={ () => removeFile(hash) }>
                                    <span className="material-symbols-outlined">delete</span>
                                </a>
                            </FilePreview>
                        </li>
                    )) }
                </ul>
            </div>
        </div>
    )
}

export {
    FileQueue as default,
    FileQueueEntry
}
