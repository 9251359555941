import useWebRTCHandler from './WebRTCHandler'

const openpgp = require('openpgp/lightweight');

function linkify(inputText: string) {
    let replacedText, replacePattern1, replacePattern2, replacePattern3;

    //URLs starting with http://, https://, or ftp://
    replacePattern1 = /(\b(https?|ftp):\/\/[-A-Z0-9+&@#\/%?=~_|!:,.;]*[-A-Z0-9+&@#\/%=~_|])/gim;
    replacedText = inputText.replace(replacePattern1, '<a href="$1" target="_blank">$1</a>');

    //URLs starting with "www." (without // before it, or it'd re-link the ones done above).
    replacePattern2 = /(^|[^\/])(www\.[\S]+(\b|$))/gim;
    replacedText = replacedText.replace(replacePattern2, '$1<a href="http://$2" target="_blank">$2</a>');

    //Change email addresses to mailto:: links.
    replacePattern3 = /(([a-zA-Z0-9\-\_\.])+@[a-zA-Z\_]+?(\.[a-zA-Z]{2,6})+)/gim;
    replacedText = replacedText.replace(replacePattern3, '<a href="mailto:$1">$1</a>');

    return replacedText;
}

const createBadge = (username: string) => {
    return username
        .split(' ')
        .map(chunk => chunk ? (chunk + '').substring(0, 1) : null)
        .filter(c => !!c)
        .join('')
        .toUpperCase()
}

const parseMessage = (content: string) => {
    content = (content + '').trim().replace(/([^>\r\n]?)(\r\n|\n\r|\r|\n)/g, '$1<br>$2')
    content = linkify(content)
    return content
}

const parseHash = (): { [key: string]: string } => {
    let hash = window.location.hash

    if (hash.substring(0, 1) === '#') {
        hash = hash.substring(1)
    }

    const data = hash.split(',').reduce((res: object, chunk: string): object => {
        const tmp = chunk.split('=')
        if (tmp.length) {
            const [k, v] = tmp
            // @ts-ignore
            res[k] = v
        }
        return res
    }, {})

    return data
}

const formatDate = (timestamp: number): string => {
    const date = new Date()
    date.setTime(timestamp)

    const d = [
        ('0' + date.getDay()).slice(-2),
        ('0' + (date.getMonth() + 1)).slice(-2),
        date.getFullYear(),
    ].join('.')

    const t = [
        ('0' + date.getHours()).slice(-2),
        ('0' + date.getMinutes()).slice(-2),
    ].join(':')

    return [d, t].join(' ')
}

function generateUUID() { // Public Domain/MIT
    var d = new Date().getTime();//Timestamp
    var d2 = ((typeof performance !== 'undefined') && performance.now && (performance.now()*1000)) || 0;//Time in microseconds since page-load or 0 if unsupported
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function(c) {
        var r = Math.random() * 16;//random number between 0 and 16
        if(d > 0){//Use timestamp until depleted
            r = (d + r)%16 | 0;
            d = Math.floor(d/16);
        } else {//Use microseconds since page-load if supported
            r = (d2 + r)%16 | 0;
            d2 = Math.floor(d2/16);
        }
        return (c === 'x' ? r : (r & 0x3 | 0x8)).toString(16);
    });
}

const keyCache: {[str: string]: any} = {}

const readKey = async (str: string) => {
    if (typeof keyCache[str] === 'undefined') {
        keyCache[str] = await openpgp.readKey({ armoredKey: str });
    }
    return keyCache[str]
}

const createKeys = async (name: string, email: string) => {
    return await openpgp.generateKey({ curve: 'curve25519',  userIDs: [{ name, email }] })
};

const encrypt = async (text: string, key: any) => {
    const publicKey = await readKey(key);
    return await openpgp.encrypt({
        message: await openpgp.createMessage({ text }),
        encryptionKeys: publicKey
    })
}

const decrypt = async (encrypted: string, key: any) => {
    const privateKey = await readKey(key);

    const message = await openpgp.readMessage({
        armoredMessage: encrypted
    });

    const { data: decrypted, signatures } = await openpgp.decrypt({
        message,
        decryptionKeys: privateKey
    })

    return decrypted
}

const shortenFileName = (str: string) => {
    if (str.length > 20) {
        const start = str.substring(0, 8)
        const end = str.substring(str.length - 8)
        return [
            start,
            end
        ].join('...')
    }
    return str
}

const isImageType = (type?: string) => {
    if (!type) {
        return
    }

    return [
        'image/png',
        'image/jpg',
        'image/gif',
        'image/jpeg',
        'image/svg+xml',
    ].includes(type.toLowerCase())
}

async function dataURItoBlob(dataURI: string) {
    // convert base64 to raw binary data held in a string
    // doesn't handle URLEncoded DataURIs - see SO answer #6850276 for code that does this
    var byteString = atob(dataURI.split(',')[1]);

    // separate out the mime component
    var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

    // write the bytes of the string to an ArrayBuffer
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
        ia[i] = byteString.charCodeAt(i);
    }

    //Old Code
    //write the ArrayBuffer to a blob, and you're done
    //var bb = new BlobBuilder();
    //bb.append(ab);
    //return bb.getBlob(mimeString);

    //New Code
    return new Blob([ab], {type: mimeString});
}

export {
    linkify,
    createBadge,
    parseMessage,
    parseHash,
    formatDate,
    generateUUID,
    createKeys,
    encrypt,
    decrypt,
    shortenFileName,
    isImageType,
    dataURItoBlob,
    useWebRTCHandler,
}
