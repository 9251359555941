import {useWebRTCHandler} from "../lib";
import React, {useContext, useEffect, useRef, useState} from "react";
import {ClientListEntry, WSClient} from "../ws/WSClient";
import {useTranslation} from "react-i18next";

import './VideoChat.scss'
import AppLayer from "./general/AppLayer";
import AppContext from "../context/AppContext";
import ChatControlButton, {ChatControlButtonProps} from "./chat/controls/ChatControlButton";
import MaterialIcon from "./icons/MaterialIcon";
import MaterialToggleIcon from "./icons/MaterialToggleIcon";
import VideoControlButtons from "./chat/VideoControlButtons";

type Props = {
    callee: ClientListEntry|null
    client: WSClient
    context: Context
    show: Boolean,
    onVisibleChange: (visible: boolean) => void
    contentLeft: React.ReactNode
    contentRight: React.ReactNode
}

type Context = {
    id: string,
    room: string,
    email: string,
    name: string,
    status: RTCStatus,
    users: ClientListEntry[],
}

export type RTCStatus = {
    videoEnabled: boolean
    screenShareEnabled: boolean
    audioEnabled: boolean
    state: string
}

export default (props: Props) => {
    const { callee, client, context, show, onVisibleChange, contentLeft, contentRight } = props

    const { t } = useTranslation()

    const webRTCHandler = useWebRTCHandler()

    const localVideo = useRef<HTMLVideoElement>(null)
    const remoteVideo = useRef<HTMLVideoElement>(null)
    const screenShareVideo = useRef<HTMLVideoElement>(null)

    const [ localStream, setLocalStream ] = useState<MediaStream|null>(null)
    const [ remoteStream, setRemoteStream ] = useState<MediaStream|null>(null)
    const [ screenShareStream, setScreenShareStream ] = useState<MediaStream|null>(null)

    const [ visible, setVisible ] = useState<boolean>(false)

    // @ts-ignore
    const [ data, setData ] = useState<RTCStatus>({})

    const [ lastCallee, setLastCallee ] = useState<ClientListEntry|null>(null)

    const [ forceVisible, setForceVisible ] = useState<boolean>(false)

    const close = () => {
        webRTCHandler.end()
        onVisibleChange(false)
    }

    useEffect(() => {
        webRTCHandler.onLocalStream(stream => {
            console.log('rtc video', 'in view - got local stream', stream)
            setLocalStream(stream)
        })
        webRTCHandler.onRemoteStream(stream => {
            console.log('rtc video', 'in view - got remote stream', stream)
            setRemoteStream(stream)
        })
        webRTCHandler.onScreenShareStream(stream => {
            console.log('rtc video', 'in view - got screen share stream', stream)
            setScreenShareStream(stream)
        })
        webRTCHandler.onClose(() => {
            setRemoteStream(null)
        })
        webRTCHandler.onChange(() => {
            console.log('NEW DATA', webRTCHandler.getData())
            setData(webRTCHandler.getData())
        })

        if (callee && (!lastCallee || lastCallee.id !== callee.id)) {
            setLastCallee(callee)

            webRTCHandler.setClient(client)
            webRTCHandler.connect(callee.id)
        }
    }, [props])

    useEffect(() => {
        setForceVisible(show || data.state === 'connected')
    }, [show, data])

    useEffect(() => {
        if (localVideo.current) {
            localVideo.current.srcObject = localStream
        }
    }, [localStream])

    useEffect(() => {
        if (remoteVideo.current) {
            remoteVideo.current.srcObject = remoteStream
        }
    }, [remoteStream])

    useEffect(() => {
        if (screenShareVideo.current) {
            screenShareVideo.current.srcObject = screenShareStream
        }
    }, [screenShareStream])

    useEffect(() => {
        setVisible(forceVisible || data.state === 'connected')
        if (data.state === 'connected' && !show) {
            console.log('TRIGGER SHOW')
            onVisibleChange(true)
        }
    }, [forceVisible, data])

    useEffect(() => {
        onVisibleChange(visible)

        if (visible) {
            if (localVideo.current) {
                console.log('rtc video', 'view - setting local srcObject')
                localVideo.current.srcObject = localStream
            } else {
                console.log('rtc video', 'view - unable to set local srcObject')
            }
        } else {
            webRTCHandler.close()
        }
    }, [visible])

    const classNames = []
    if (localStream) classNames.push('has-local')
    if (remoteStream) classNames.push('has-remote')

    return (
        <>
            { callee && data.state === 'ringing' && (
                <>
                    <AppLayer title={ t('Anruf') || '' } className="ringing">
                        { t('%s ruft Sie an'.replace('%s', callee.name)) }
                        <div className="buttons">
                            <button onClick={() => webRTCHandler.answerCall(callee)} className="green">
                                <span className="material-symbols-sharp">call_end</span>
                            </button>
                            <button onClick={() => webRTCHandler.end()} className="red">
                                <span className="material-symbols-sharp">call_end</span>
                            </button>
                        </div>
                    </AppLayer>
                </>
            )}
            {(show || data.state === 'connected') && (
                <div id="video-chat" className={classNames.join(' ')}>
                    <pre className="debug">
                        {JSON.stringify({ ...data, show }, null, 2)}
                    </pre>
                    <div className="video-area">
                        {localStream && (
                            <div className="local-video-wrapper">
                                <video className="local" ref={localVideo} autoPlay={true} muted={true} playsInline></video>
                            </div>
                        )}
                        {remoteStream && (
                            <div className="remote-video-wrapper">
                                <video className="remote" ref={remoteVideo} autoPlay={true} playsInline></video>
                                <span>{callee?.name}</span>
                            </div>
                        )}
                        {screenShareStream && (
                            <div className="screen-share-wrapper">
                                <header>
                                    <span className="title">
                                        You're sharing your screen
                                    </span>
                                    <a href="#" onClick={() => webRTCHandler.stopScreenShare()}>
                                        <span className="material-symbols-sharp">close</span>
                                    </a>
                                </header>
                                <video className="share" ref={screenShareVideo} autoPlay={true} playsInline></video>
                            </div>
                        )}
                    </div>
                    <div className="controls">
                        <div className="left">
                            <div className="inner">
                                {contentLeft}
                            </div>
                        </div>

                        <div className="center">
                            <div className="inner">
                                <VideoControlButtons
                                    callee={callee}
                                    data={data}
                                    webRTCHandler={webRTCHandler}/>
                            </div>
                        </div>

                        <div className="right">
                            <div className="inner">
                                {contentRight}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    )
}
