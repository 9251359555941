import React from "react"

import './UserBadge.scss'

type Props = {
    name: string
}

export default ({ name }: Props) => {
    const content = name
        .toUpperCase()
        .split(' ')
        .map(chunk => chunk.substring(0, 1))
        .slice(0, 2)
        .join('')

    return <div className={'user-badge'}>
        { content }
    </div>
}