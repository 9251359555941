import React, {ReactElement, useRef, useState} from "react";
import base64 from 'base-64'

import {t} from "i18next";
import {useAppSelector} from "../store/hooks";
import AppLayer from "./general/AppLayer";

import './SignatureRequest.scss'
import {Context} from "../App";
import {useDispatch} from "react-redux";
import useWSClient, {SignedPDFRequest} from "../ws/WSClient";
import {encrypt} from "../lib";

interface Props {
    context: Context
}

const signDocument = async (url: string) => {
    if (!url) {
        return null
    }

    return await new Promise((resolve, reject) => {
        var op = {
            content: url,
            // connector: "bku", // Diese Eigenschaft ist optional, wenn ausgelassen wird dem Benutzer gefragt. Moegliche Werte sind: "bku" -> Signatur mit der lokalen BKU, "mobilebku" -> Signatur mit der Handy Signatur
            uiId: "is-austria-container", // Diese Eigenschaft ist optional, wenn ausgelassen wird ein Overlay erzeugt. Dies ist die id eines div elements in das das iframe zur Benutzerinteraktion eingefuegt werden soll.
            sig_pos_x: 60, // Die X-Koordinate der links-unten Signaturblockecke.
            sig_pos_y: 200, // Die X-Koordinate der links-unten Signaturblockecke.
            sig_pos_p: 2, // Die Seite auf der der Signaturblock platziert werden soll.
            sig_pos_w: 480, //Die Breite des Signaturblocks
            sig_pos_f: 100 // Die Hoehe der Fuszzeile ueber der der Signaturblock platziert werden soll.
        };
        window.pdfAs.signPdf(op, function(error, cause) {
            reject({ error, cause })
        }, function(pdfurl) {
            resolve(pdfurl)
        });
    })
}

const arrayBufferToBase64 = ( buffer: ArrayBuffer ) => {
    var binary = '';
    var bytes = new Uint8Array( buffer );
    var len = bytes.byteLength;
    for (var i = 0; i < len; i++) {
        binary += String.fromCharCode( bytes[ i ] );
    }
    return base64.encode( binary );
}

export default ({ context }: Props): ReactElement => {
    const state = useAppSelector(state => state.signature)
    const dispatch = useDispatch()
    const client = useWSClient()

    const [ signing, setSigning ] = useState(false)
    const [ processing, setProcessing ] = useState(false)
    const [ success, setSuccess ] = useState(false)

    if (!state.fileToSign) {
        return <></>
    }

    const startSign = async () => {
        setSigning(true)

        let signedUrl = null

        if (false) {
            signedUrl = await new Promise(resolve => {
                document.getElementById('is-austria-container').innerHTML = 'Simulating ID-Austria for 2 seconds...'
                setTimeout(() => {
                    resolve('https://eu2.contabostorage.com/i-chat-dev/624e9378-5a3d-4f42-b37f-d228a70163ff/Rechnung.pdf?X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Content-Sha256=UNSIGNED-PAYLOAD&X-Amz-Credential=d4f72a9ea7c94065b9b25b58b55894fb%2F20230615%2Feu-central-1%2Fs3%2Faws4_request&X-Amz-Date=20230615T172813Z&X-Amz-Expires=3600&X-Amz-Signature=4827a761ecfbdd04625aa3d0e5a6b8500169e6cdf3d403c25baabbb3acc78e3d&X-Amz-SignedHeaders=host&x-id=GetObject')
                    setSigning(false)
                    setProcessing(true)
                }, 2000)
            })
        } else {
            try {
                signedUrl = await signDocument(file.url || '')
                setProcessing(true)
            } catch ({ error, cause }) {
                alert(t('Die Signatur konnte nicht erfolgreich durchgeführt werden'))
                console.error(error, cause)
            }

            setSigning(false)
        }

        document.getElementById('is-austria-container').innerHTML = ''

        if (signedUrl) {
            fetch(signedUrl)
                .then(res => res.arrayBuffer())
                .then(buf => {
                    (async () => {
                        const content = arrayBufferToBase64(buf)

                        const request: SignedPDFRequest = {
                            room: context.room,
                            id: context.id,
                            url: file.url,
                            content,
                        }

                        await client.send('signed', request)

                        setProcessing(false)
                        setSuccess(true)
                    })()
                })
        }
    }

    const cancel = () => {
        setSigning(false)
        setSuccess(false)
        dispatch({ type: 'signature/cancelSigning' })
    }

    const file = state.fileToSign

    return <AppLayer className="signature" title={ t('Datei unterschreiben') || '' } backdrop>

        { !signing && !success && !processing && <>
            <div style={{ marginBottom: '30px' }}>
                { t('Sie können diese Datei "%s" mit Handysignatur oder ID Austria unterschreiben.').replace('%s', file.name) }
            </div>
        </>}

        <div id="is-austria-container"></div>

        { !signing && !success && ! processing && <>
            <button className="button primary" onClick={startSign}>
                Jetzt unterschreiben
            </button>

            <div className="buttons">
                <a className="button" href={file.url || ''} target="_blank">
                    { t('Datei ansehen') }
                </a>
                <button onClick={cancel}>
                    { t('Abbrechen') }
                </button>
            </div>
        </>}

        { processing && <>
            { t('Die Datei wird verarbeitet.') }

            <div style={{ margin: '20px' }}>
                <span className="material-symbols-sharp">hourglass_top</span>
            </div>
        </>}

        { success && <>
            { t('Sie haben Die Datei erfolgreich unterschrieben.') }

            <div className="buttons">
                <div></div>
                <button onClick={cancel}>
                    { t('Schließen') }
                </button>
            </div>
        </>}
    </AppLayer>
}
