import React, {useState} from "react";
import {MessageFileObject} from "../ws/WSClient";
import {shortenFileName} from "../lib";
import FsLightbox from 'fslightbox-react';
import {useTranslation} from "react-i18next";
import {useDispatch} from "react-redux";

type Props = {
    isSender: boolean
    senderName: string
    files: MessageFileObject[]
}

const FileList = ({ isSender, senderName, files }: Props) => {
    const { t } = useTranslation()
    const dispatch = useDispatch()

    const [ showLightBox, setShowLightbox ] = useState(false)

    const listClassName = ['file-list']

    if (isSender) {
        listClassName.push('is-sender')
    }

    // @ts-ignore
    const images: string[] = files
        .map(f => f.url)
        .filter(url => !!url)

    const items = files.filter(f => !!f && !!f.name).map(file => (
        <React.Fragment key={file.url || file.name}>
            <li title={ file.name } data-mime={file.type}>
                <span>
                    {file.url && (
                        <>
                        {file.type.split('/')[0] === 'image'
                            ? <a href="#" onClick={ () => setShowLightbox(!showLightBox) }>
                                <img src={file.signedUrl ? file.signedUrl : file.url}/>
                              </a>
                            : <div>
                                <a href={file.signedUrl ? file.signedUrl : file.url} target="_blank">
                                    <span className="material-symbols-sharp">download</span>
                                </a>
                              </div>}
                        </>
                    )}
                    {!file.url && (
                        <span className="material-symbols-sharp">hourglass_top</span>
                    )}
                    <span className="filename">{ shortenFileName(file.name) }</span>
                </span>
                { file.signedUrl && <>
                    <span className="material-symbols-outlined symbol-signed" title={ t('Diese Datei wurde signiert') }>military_tech</span>
                </>}
            </li>

            { isSender && file.signatureRequested && !file.signedUrl && (
                <li className="sign-info">
                    <span className="material-symbols-sharp">hourglass_top</span>
                    <br/>
                    { t('Warte auf Unterschrift') }
                </li>
            )}

            { ! isSender && file.signatureRequested && !file.signedUrl && (
                <li className="sign-info">
                    { t('%s bittet Sie diese Datei zu unterschreiben.').replace('%s', senderName) }
                    <br/>
                    <button className="button" onClick={() => dispatch({ type: 'signature/startSigning', payload: file})}>
                        { t('Unterschreiben') }
                    </button>
                </li>
            )}
        </React.Fragment>
    ))

    return (
        <div className={listClassName.join(' ')}>
            <ul>
                {items}
            </ul>
            <FsLightbox
                toggler={ showLightBox }
                sources={ images }
                types={ new Array(images.length).fill('image') }
            />
        </div>
    )
}

export default FileList
