import React, {useState} from "react";
import {createBadge} from "../lib";
import useWSClient, {ClientListEntry} from "../ws/WSClient";

type Props = {
    users: ClientListEntry[]
}

const UserList = ({ users }: Props) => {
    const client = useWSClient()

    const [ selected, setSelected ] = useState<string>()

    const onClose = () => setSelected(null)

    return (
        <>
            <ul className="user-list">
                {users.map((user, i: number) => (
                    <li className="user" key={i} onClick={ () => client.getName() !== user.name && setSelected(user.id) } style={{ cursor: client.getName() !== user.name ? 'pointer' : 'default' }}>
                        <div className="badge">
                            <div className="inner">
                                { createBadge(user.name) }
                            </div>
                        </div>
                        <div className="username">{ user.name }</div>
                    </li>
                ))}
            </ul>
        </>
    )
}

export default UserList
