import useWSClient, {ClientListEntry, WSClient} from "../ws/WSClient";
import {RTCStatus} from "../components/VideoChat";

export class WebRTCHandler
{
    private localStream: MediaStream|null = null
    private remoteStream: MediaStream|null = null
    private screenShareTrack: MediaStreamTrack|null = null

    private screenShareEnabled = false
    private videoEnabled = true
    private audioEnabled = true

    private connection: RTCPeerConnection|null = null

    private client: WSClient|null = null

    private callbacks: { [key: string]: Function[] } = {
        onCall: [],
        onLocalStream: [],
        onRemoteStream: [],
        onScreenShareStream: [],
        onChange: [],
        onClose: [],
        onDebug: [],
    }

    private rcpt: string|null = null

    private state: string = 'idle'

    private offer: RTCSessionDescriptionInit|null = null
    private initialized: Boolean = false;
    private initStarted: Boolean = false;

    private debugLines: string[] = []

    connect(rcpt: string) {
        this.rcpt = rcpt

        this.openConnection()
    }

    openConnection() {
        this.connection = new RTCPeerConnection({
            'iceServers': [
                {'urls': 'stun:stun.l.google.com:19302'},
                {'urls': 'turn:turn.sourcefactory.at:3478', username: 'inpriton', credential: '4f864cbbd74c2b3059b5598fc1544f1e70a4f3d172d6824d916b1419b0892693'},
            ]
        })

        this.remoteStream = new MediaStream()

        this.connection.onicecandidate = (event) => {
            if (event.candidate) {
                // console.log('rtc', 'got icecandidate', event.candidate)
                this.client?.send('webrtc', {
                    type: "candidate",
                    room: this.client.getRoomUuid(),
                    rcpt: this.rcpt,
                    candidate: event.candidate
                })
            } else {
                console.warn('rtc video', 'no event.candidate in connection.onicecandidate')
            }
        }

        this.connection.ontrack = (event) => {
            console.log('rtc video', 'got remote track', event.track)
            this.remoteStream?.addTrack(event.track)
            this.setState('connected')
            this.fire('onRemoteStream', this.remoteStream)
        }

        console.log('rtc', 'setup connection', this.connection)
        this.debug('setup connection')

        this.localStream?.getTracks().forEach(track => {
            console.log('rtc video', 'init() - adding track', track)
            this.connection?.addTrack(track)
        })
    }

    public refresh() {
        console.log('REFRESH CONNECTION')
        this.connection?.close()
        this.openConnection()
    }

    debug(line: string) {
        this.debugLines.push(line)
        this.fire('onDebug', this.debugLines)
    }

    public setClient(client: WSClient) {
        this.client = client

        this.client.onWebRTCAction(async data => {
            switch(data.type) {
                case "offer":
                    console.log('rtc', 'got offer', data.offer)
                    this.handleOffer(data.offer);
                    break;
                case "answer":
                    console.log('rtc', 'got answer', data.answer)
                    await this.handleAnswer(data.answer);
                    break;
                case "candidate":
                    // console.log('rtc', 'got candidate', data.candidate)
                    await this.handleCandidate(data.candidate);
                    break;
                case "leave":
                    await this.handleLeave();
                    break;
                default:
                    break;
            }
        })
    }

    private async handleAnswer(answer: RTCSessionDescriptionInit) {
        this.debug('handleAnswer()')
        console.log('rtc', 'handleAnswer()')
        this.connection?.setRemoteDescription(new RTCSessionDescription(answer))
    }

    private async handleCandidate(candidate: RTCIceCandidateInit) {
        this.debug('handleCandidate()')
        console.log('rtc', 'handleCandidate()', candidate)
        this.connection?.addIceCandidate(new RTCIceCandidate(candidate))
    }

    private async handleLeave() {
        this.debug('handleLeave()')
        console.log('rtc', 'got leave', 'state', this.state)

        if (!['calling', 'ringing'].includes(this.state)) {
            this.stopRemoteStream()
        }

        this.setState('idle')
        this.refresh()
    }

    private async handleOffer(offer: RTCSessionDescriptionInit) {
        console.log('rtc', 'got offer', offer)
        this.debug('handleOffer()')
        this.offer = offer
        this.setState('ringing')
    }

    public onChange(callback: Function) {
        this.callbacks.onChange.push(callback)
        callback()
    }

    public onCall(callback: Function) {
        this.callbacks.onCall.push(callback)
    }

    public onLocalStream(callback: (stream: MediaStream) => void) {
        this.callbacks.onLocalStream.push(callback)
    }

    public onDebug(callback: (lines: string[]) => void) {
        this.callbacks.onDebug.push(callback)
    }

    public onRemoteStream(callback: (stream: MediaStream) => void) {
        this.callbacks.onRemoteStream.push(callback)
    }

    public onScreenShareStream(callback: (stream: MediaStream) => void) {
        this.callbacks.onScreenShareStream.push(callback)
    }

    public onClose(callback: Function) {
        this.callbacks.onClose.push(callback)
    }

    public close() {
        this.stopLocalStream()
        this.initStarted = false
        this.initialized = false
    }

    public async startScreenShare() {
        const screenCaptureStream = await this.getLocalScreenCaptureStream();

        if (screenCaptureStream) {
            const mediaStreamTracks = screenCaptureStream.getVideoTracks()
            console.log('rtc video', 'replace camera track with screen track', mediaStreamTracks);
            this.screenShareTrack = mediaStreamTracks[0];
            var sender = this.connection?.getSenders().find((s) => {
                return s.track?.kind === this.screenShareTrack?.kind;
            });
            sender?.replaceTrack(this.screenShareTrack);
            // this.localStream?.getTracks().forEach(t => this.localStream?.removeTrack(t))
            // mediaStreamTracks.forEach(t => this.localStream?.addTrack(t))

            this.screenShareEnabled = true
            this.fire('onChange')

            this.fire('onScreenShareStream', screenCaptureStream)

            this.screenShareTrack.onended = ()=> {
                console.log('rtc video', 'screen share track ended - replace with camera track', this.localStream?.getTracks()[1]);
                if (this.localStream?.getTracks()[1]) {
                    sender?.replaceTrack(this.localStream?.getTracks()[1]);
                }
                this.screenShareEnabled = false
                this.fire('onChange')

                this.fire('onScreenShareStream', null)
            }
        }
    }

    public async stopScreenShare() {
        if (this.screenShareTrack) {
            var sender = this.connection?.getSenders().find((s) => {
                return s.track?.kind === this.screenShareTrack?.kind;
            });

            this.screenShareTrack.stop()

            if (this.localStream?.getTracks()[1]) {
                sender?.replaceTrack(this.localStream?.getTracks()[1]);
            }

            this.screenShareEnabled = false
            this.fire('onChange')

            this.fire('onScreenShareStream', null)
        }
    }

    async getLocalScreenCaptureStream() {
        try {
            const constraints = { video: { cursor: 'always' }, audio: false };
            const screenCaptureStream = await navigator.mediaDevices.getDisplayMedia(constraints);

            return screenCaptureStream;
        } catch (error) {
            console.error('failed to get local screen', error);
        }
    }

    private stopLocalStream() {
        console.log('rtc video', 'request to stop local stream')
        this.localStream?.getTracks().forEach(track => {
            console.log('rtc video', 'stopping local track', track)
            track.stop()
            this.localStream?.removeTrack(track)
        })
        this.localStream = null
        this.fire('onLocalStream', null)
        this.initStarted = false
        this.initialized = false
    }

    private stopRemoteStream() {
        console.log('rtc video', 'request to stop remote stream')
        this.remoteStream?.getTracks().forEach(track => {
            console.log('rtc video', 'stopping remote track', track)
            track.stop()
            this.remoteStream?.removeTrack(track)
        })
        this.remoteStream = null
        this.fire('onRemoteStream', null)
    }

    public end() {
        console.log('rtc video', 'end() - replacing all senders tracks with null')
        this.connection?.getSenders().forEach(sender => sender.replaceTrack(null))

        this.client?.send('webrtc', {
            type: 'leave',
            room: this.client?.getRoomUuid(),
        })

        this.fire('onClose')
    }

    public declineCall() {
        console.log('rtc video', 'declineCall() - replacing all senders track with null')
        this.connection?.getSenders().forEach(sender => sender.replaceTrack(null))

        this.client?.send('webrtc', {
            type: 'leave',
            room: this.client?.getRoomUuid(),
        })

        this.fire('onClose')
    }

    public stopCalling() {
        console.log('rtc video', 'stopCalling() - replacing all senders track with null')
        this.connection?.getSenders().forEach(sender => sender.replaceTrack(null))

        this.client?.send('webrtc', {
            type: 'leave',
            room: this.client?.getRoomUuid(),
        })

        this.fire('onClose')
    }

    public getData() {
        return {
            screenShareEnabled: this.screenShareEnabled,
            videoEnabled: this.videoEnabled,
            audioEnabled: this.audioEnabled,
            state: this.state,
        }
    }

    public toggleMute() {
        if (this.localStream) {
            console.log('rtc video', 'toggleMute()')
            for (const track of this.localStream.getAudioTracks()) {
                console.log('rtc video', 'disabling track', track)
                track.enabled = !track.enabled
                this.audioEnabled = track.enabled
            }
        } else {
            console.log('rtc video', 'toggleMute() - no local stream')
        }
        // @ts-ignore
        this.connection?.addStream(this.localStream)
        this.fire('onChange')
    }

    public toggleVideo() {
        if (this.localStream) {
            console.log('rtc video', 'toggleMute()')
            for (const track of this.localStream.getVideoTracks()) {
                console.log('rtc video', 'disabling track', track)
                track.enabled = !track.enabled
                this.videoEnabled = track.enabled
            }
        } else {
            console.log('rtc video', 'no local stream')
        }
        // @ts-ignore
        this.connection?.addStream(this.localStream)
        this.fire('onChange')
    }

    async requestCall(otherUser: ClientListEntry) {
        console.log('starting call to', otherUser)

        this.connection
            ?.createOffer({ iceRestart: true })
            .then(offer => this.connection?.setLocalDescription(new RTCSessionDescription(offer)))
            .then(() => {
                this.client?.send('webrtc', {
                    type: 'offer',
                    room: this.client?.getRoomUuid(),
                    rcpt: otherUser.id,
                    offer: this.connection?.localDescription
                })
            })
            .then(() => {
                this.setState('calling')
            })
    }

    async answerCall(otherUser: ClientListEntry) {
        if (this.offer) {
            if (!this.initialized) {
                await this.init()
            }

            this.connection?.setRemoteDescription(new RTCSessionDescription(this.offer))
                .then(() => this.connection?.createAnswer())
                // @ts-ignore
                .then((answer: RTCSessionDescriptionInit) => this.connection.setLocalDescription(new RTCSessionDescription(answer)))
                .then(() => this.client?.send('webrtc', {
                    type: 'answer',
                    room: this.client?.getRoomUuid(),
                    rcpt: otherUser.id,
                    answer: this.connection?.localDescription
                }))
        } else {
            console.error('cant answer a call without offer')
        }
    }

    private fire(event: string, payload: any = null) {
        if (event === 'onClose') {
            this.refresh()
        }

        if (typeof this.callbacks[event] !== 'undefined') {
            console.log('rtc', 'fire', event)
            this.callbacks[event].forEach(cb => cb(payload))
        } else {
            console.log('invalid event', event)
        }
    }

    private setState(state: string) {
        console.log('rtc', 'new state', state)
        this.state = state
        this.fire('onChange')
    }

    public async init(stream: MediaStream|null = null, status: RTCStatus|null = null) {
        if (this.initStarted) {
            return
        }
        this.initStarted = true

        console.log('rtc video', 'init()')

        if (!navigator.mediaDevices.getUserMedia) {
            alert('Your browser does not support video calls')
            return
        }

        console.log('preparing local stream')

        try {
            if (stream) {
                this.localStream = stream
            } else {
                this.localStream = await navigator.mediaDevices.getUserMedia({ audio: true, video: true })
            }

            if (status) {
                this.videoEnabled = status.videoEnabled
                this.audioEnabled = status.audioEnabled
                this.screenShareEnabled = status.screenShareEnabled
            }

            console.log('rtc video', 'init() - got local stream', this.localStream)

            this.fire('onLocalStream', this.localStream)

            this.localStream.getTracks().forEach(track => {
                console.log('rtc video', 'init() - adding track', track)
                this.connection?.addTrack(track)
            })
        } catch (err) {
            alert('an error occurred')
            console.error(err)
        }

        this.initialized = true
    }
}

let handler: WebRTCHandler|null = null

const useWebRTCHandler = (): WebRTCHandler => {
    if (!handler) {
        handler = new WebRTCHandler()
    }
    return handler
}

export default useWebRTCHandler
