import React from 'react'

import './AppLayer.scss'

interface Props {
    title: String,
    backdrop?: boolean,
    className?: String,
    size?: "sm"|"md"|"lg"|"full"
    children: any,
}

export default ({ title, backdrop = false, className = '', size = "md", children }: Props) => {
    const classNames = [
        'app-layer',
        `size-${size}`,
        ...className.split(' ')
    ]

    return <>
            { backdrop && <div className="app-layer-backdrop"></div> }
            <div className={ classNames.join(' ') }>
                <header className="title">
                    { title }
                </header>
                <div className="body">
                    { children }
                </div>
            </div>
        </>
}
