import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {Message, UserMessage} from "../../ws/WSClient";

interface MessageState {
    messages: Message[]
}

const initialState: MessageState = {
    messages: []
}

const messagesSlice = createSlice({
    name: 'messages',
    initialState,
    reducers: {
        add: (state, action: PayloadAction<Message>) => {
            state.messages = [...state.messages, action.payload]
        },
        set: (state, action) => {
            console.log('got messages', action)
            state.messages = action.payload
        }
    }
})

export default messagesSlice.reducer
