import React from "react"
import {ClientListEntry} from "../../ws/WSClient";
import ChatControlButton, {ChatControlButtonProps} from "./controls/ChatControlButton";
import MaterialToggleIcon from "../icons/MaterialToggleIcon";
import MaterialIcon from "../icons/MaterialIcon";
import {RTCStatus} from "../VideoChat";
import {WebRTCHandler} from "../../lib/WebRTCHandler";
import {useTranslation} from "react-i18next";
import SVGToggleIcon from "../icons/SVGToggleIcon";

type Props = {
    callee: ClientListEntry|null
    data: RTCStatus
    webRTCHandler: WebRTCHandler
}

type ButtonList = ChatControlButtonProps[]

export default ({ callee, data, webRTCHandler } : Props) => {
    const {t} = useTranslation()

    const onScreenShareClick = () => {
        if (!data.screenShareEnabled) {
            webRTCHandler.startScreenShare()
        } else {
            webRTCHandler.stopScreenShare()
        }
    }

    const state = data.state

    const buttons: ButtonList = [
        {
            onClick: onScreenShareClick,
            active: data.screenShareEnabled,
            title: t('videoControl.screenShare.enable'),
            activeTitle: t('videoControl.screenShare.disable'),
            icon: new SVGToggleIcon('screen-share', 'screen-share-off')
        },
        {
            onClick: () => webRTCHandler.toggleVideo(),
            active: data.videoEnabled,
            title: t('videoControl.camera.enable'),
            activeTitle: t('videoControl.camera.disable'),
            icon: new SVGToggleIcon('video-off', 'video')
        },
        {
            onClick: () => webRTCHandler.toggleMute(),
            active: data.audioEnabled,
            title: t('videoControl.mic.enable'),
            activeTitle: t('videoControl.mic.disable'),
            icon: new SVGToggleIcon('microphone-off', 'microphone')
        },
    ]

    if (callee) {
        if (state === 'idle') {
            buttons.push({
                onClick: () => webRTCHandler.requestCall(callee),
                className: 'green',
                title: t('videoControl.enter'),
                icon: new MaterialIcon('call_end'),
            })
        }

        if (state === 'ringing') {
            buttons.push({
                onClick: () => webRTCHandler.answerCall(callee),
                className: 'green',
                title: t('videoControl.accept'),
                icon: new MaterialIcon('call_end'),
            })
            buttons.push({
                onClick: () => webRTCHandler.end(),
                className: 'red',
                title: t('videoControl.decline'),
                icon: new MaterialIcon('close'),
            })
        }
    }

    if (state === 'calling') {
        buttons.push({
            onClick: () => webRTCHandler.stopCalling(),
            className: 'red',
            title: t('videoControl.hangUp'),
            icon: new MaterialIcon('call_end'),
        })
    }

    if (state === 'connected') {
        buttons.push({
            onClick: () => webRTCHandler.end(),
            className: 'red',
            title: t('videoControl.exit'),
            icon: new MaterialIcon('call_end'),
        })
    }

    return (
        <>
            { buttons.map(props => (
                <ChatControlButton {...props}/>)
            )}
        </>
    )
}