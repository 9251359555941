import React from "react";
import { ReactSVG } from "react-svg";

import './SVGIcon.scss'

export default class SVGIcon implements IconInterface
{
    name: string

    constructor(name: string) {
        this.name = name
    }

    get(): React.ReactNode {
        const svg = require(`./svg/${this.name}.svg`)

        return (
            <ReactSVG src={svg} className="svg-icon"/>
        )
    }
}