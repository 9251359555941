import React, {CSSProperties, MouseEventHandler} from "react";

import './ChatControlButton.scss'

export type ChatControlButtonProps = {
    onClick: MouseEventHandler<HTMLButtonElement>
    icon: IconInterface
    title?: string|null
    activeTitle?: string|null
    active?: boolean
    disabled?: boolean
    className?: string|null
    counter?: number
    hasWarning?: boolean
    style?: CSSProperties
}

export default ({ icon, onClick, active = false, disabled = false, title = null, activeTitle = null, className = null, counter = 0, hasWarning = false, style = {} }: ChatControlButtonProps) => {
    const iconNode = icon.get(active)

    const titleToShow = active
        ? activeTitle || title
        : title

    const classNames = (className || '').split(' ').map(chunk => chunk.trim()).filter(chunk => !!chunk)
    if (disabled && !classNames.includes('disabled')) {
        classNames.push('disabled')
    }

    return (
        <button onClick={onClick} title={titleToShow || ''} className={'action-button ' + classNames.join(' ')} style={style}>
            { iconNode }
            { counter ? (
                <i className="counter">{counter}</i>
            ) : ''}
            { hasWarning ? (
                <i className="warning">
                    !
                </i>
            ) : ''}
        </button>
    )
}