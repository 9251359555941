import React, {ChangeEvent, createRef, ReactElement, useState} from "react";

import {t} from "i18next";
import {useAppSelector} from "../store/hooks";
import AppLayer from "./general/AppLayer";

import './SignatureRequest.scss'
import FilePreview from "./general/FilePreview";
import {useDispatch} from "react-redux";
import useWSClient, {MessageRequest} from "../ws/WSClient";
import {encrypt} from "../lib";
import {Context} from "../App";

interface FileData {
    name: string,
    type: string,
    size: number,
    dataUri?: string
}

interface Props {
    context: Context
}

export default ({ context }: Props): ReactElement => {
    const state = useAppSelector(state => state.signature)

    const [ error, setError ] = useState<string|null>(null)

    const [ fileData, setFileData ] = useState<FileData|null>(null)

    const client = useWSClient()

    const dispatch = useDispatch()

    if (state.showOutgoing) {
        const input = createRef<HTMLInputElement>()

        const trigger = () => input.current?.click()

        const cancel = () => {
            setError(null)
            setFileData(null)

            dispatch({ type: 'signature/cancelRequest' })
        }

        const send = async () => {
            if (fileData) {
                const request: MessageRequest = {
                    room: context.room,
                    id: context.id,
                    files: [ {
                        name: fileData.name,
                        type: fileData.type,
                        size: fileData.size,
                        data: fileData.dataUri,
                        signatureRequested: true,
                    } ],
                    rcpts: await Promise.all(context.users.map(async (u: any) => {
                        return {
                            id: u.id,
                            data: await encrypt('', u.pubkey)
                        }
                    }))
                }

                await client.sendMessage(request)

                setError(null)
                setFileData(null)

                dispatch({ type: 'signature/cancelRequest' })
            }
        }

        const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
            setError(null)
            setFileData(null)

            if (e.target.files?.length) {
                const file = e.target.files[0]

                setFileData({
                    name: file.name,
                    type: file.type,
                    size: file.size,
                })

                try {
                    const dataUri = await new Promise<string>((resolve, reject) => {
                        const reader = new FileReader()
                        reader.onloadend = res => {
                            resolve(res.target?.result?.toString() || '')
                        }
                        reader.onerror = res => {
                            reject(res.target?.error?.message)
                        }
                        reader.readAsDataURL(file)
                    })

                    setFileData({
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        dataUri,
                    })
                } catch (err: any) {
                    setError(err)
                }
            }
        }

        return <AppLayer className="signature-request" title={ t('Signatur beantragen') || '' } backdrop>
            { ! fileData && (
                <>
                    <label>
                        { t('Bitte klicken Sie hier um eine Datei zu wählen') }:
                        <input type="file" ref={ input } style={{ display: 'none' }} onChange={ onChange }/>
                    </label>
                    <div>
                        <a href="#" onClick={ trigger } title={ t('Datei hochladen') }>
                            <span className="material-symbols-sharp">photo_library</span>
                        </a>
                    </div>

                    <div className="buttons">
                        <div></div>
                        <button onClick={ cancel }>Abbrechen</button>
                    </div>
                </>
            )}

            { fileData && (
                <>
                    <div>
                        <FilePreview loading={ !fileData.dataUri } name={fileData.name} data={ fileData }/>
                    </div>

                    <div className="buttons">
                        { fileData.dataUri ? <button onClick={ send }>Senden</button> : <div></div> }
                        <button onClick={ cancel }>Abbrechen</button>
                    </div>
                </>
            )}

            { error && <div className="error">Fehler: { error }</div> }

        </AppLayer>
    }

    return <></>
}
