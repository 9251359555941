import {createSlice} from "@reduxjs/toolkit";
import {MessageFileObject} from "../../ws/WSClient";

type FileList = {
    [messageId: string]: MessageFileObject[]
}

interface FilesState {
    files: FileList
}

const initialState: FilesState = {
    files: {}
}

const filesSlice = createSlice({
    name: 'files',
    initialState,
    reducers: {
        add: (state, action) => {
            const { message, file } = action.payload
            console.log('new file', message, file)

            const currentFiles = { ...state.files }
            if (typeof currentFiles[message] === 'undefined') {
                currentFiles[message] = []
            }
            currentFiles[message].push(file)
            console.log('set files', currentFiles)

            state.files = currentFiles
        },
    }
})

export default filesSlice.reducer
