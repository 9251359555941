export default {
    translations: {
        you: 'You',
        chat: {
            headline: 'Messages',
            encryptionFailed: 'Decryption failed',
            header: '{{names}} and you',
            noOtherUsers: 'No other person joined yet',
            newMessage: 'New message',
            sendMessage: 'Send message',
            uploadFile: 'Upload file',
            userJoined: '<i>{{name}}</i> joined the chat',
            userLeft: '<i>{{name}}</i> left the chat',
            typing_one: '<i>{{name}}</i> is typing',
            typing_other: '<i>{{names}}</i> and <i>{{name}}</i> are typing'
        },
        signature: {
            requestSignature: 'Request signature',
        },
        videoControl: {
            enter: 'Enter',
            accept: 'Accept',
            decline: 'Decline',
            hangUp: 'Hang up',
            exit: 'Exit',
            screenShare: {
                enable: 'Share your screen',
                disable: 'End screen sharing'
            },
            camera: {
                enable: 'Activate camera',
                disable: 'Deactivate camera'
            },
            mic: {
                enable: 'Activate microphone',
                disable: 'Deactivate microphone'
            },
            chat: {
                show: 'Show chat',
                hide: 'Hide chat'
            }
        },
        lobby: {
            button: {
                video: {
                    enable: 'Turn on video',
                    disable: 'Turn off video',
                },
                microphone: {
                    enable: 'Turn on microphone',
                    disable: 'Mute microphone',
                },
                settings: 'Device settings',
            },
            loading: 'Loading',
            camera: 'Camera',
            microphone: 'Microphone',
            yourName: 'Your Name',
            enterNow: 'Join now',
            leave: 'Leave',
            noPermission: 'No Permission',
        }
    }
}
