import React, {createRef, useEffect, useState} from "react";
import UploadField from "./UploadField";
import FileDropField from "./FileDropField";
import FileQueue, {FileQueueEntry} from "./FileQueue";
import {useTranslation} from "react-i18next";

type Props = {
    onSendMessage: (message: string, files: FileQueueEntry[]) => void,
    onTypeStateChange: (typing: boolean) => void,
}

let typingTimeout: any = null

let clearFileQueueCallback: () => void | null

const setClearFileQueueCallback = (cb: () => void) => {
    clearFileQueueCallback = cb
}

const MessageComposeField = ({ onSendMessage, onTypeStateChange }: Props) => {
    const [ message, setMessage ] = useState<string>('')
    const [ shiftPressed, setShiftPressed ] = useState<boolean>(false)
    const [ typing, setTyping ] = useState<boolean>(false)
    const [ typingInitialized, setTypingInitialized] = useState<boolean>(false)
    const [ newFiles, setNewFiles ] = useState<File[]>([])
    const [ filesLoading, setFiledsLoading ] = useState<boolean>(false)
    const [ fileList, setFileList ] = useState<FileQueueEntry[]>([])

    const { t } = useTranslation()

    const onMessageKeyDown = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key.toLowerCase() === 'shift') {
            setShiftPressed(true)
        }
        if (!shiftPressed && e.key.toLowerCase() === 'enter') {
            e.preventDefault()
            send()
        }
    }

    const onMessageKeyUp = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
        if (e.key.toLowerCase() === 'shift') {
            setShiftPressed(false)
        }

        if (typingTimeout) {
            window.clearTimeout(typingTimeout)

            typingTimeout = window.setTimeout(() => {
                setTyping(false)
            }, 2000)
        }

        if (!typing) {
            setTyping(true)

            typingTimeout = window.setTimeout(() => {
                setTyping(false)
                typingTimeout = null
            }, 4000)
        }
    }

    const onFilesSelected = (files: FileList) => {
        setNewFiles(Array.from(files))
    }

    const send = () => {
        if (filesLoading) {
            return
        }
        if (!message.length && !fileList.length) {
            return
        }
        onSendMessage(message, fileList)
        setMessage('')

        if (clearFileQueueCallback) {
            clearFileQueueCallback()
        }
    }

    const clearAction = (cb: () => void) => {
        setClearFileQueueCallback(cb)
    }

    useEffect(() => {
        if (!typingInitialized) {
            setTypingInitialized(true)
            return
        }
        onTypeStateChange(typing)
    }, [typing])

    const sendButtonClasses = []
    if (filesLoading) {
        sendButtonClasses.push('disabled')
    }

    return (
        <div className="compose">
            <div className="inner">
                <div className="textarea">
                    <FileQueue files={newFiles} onChange={setFileList} onLoading={setFiledsLoading}
                               clearAction={clearAction}/>

                    <textarea placeholder={t('chat.newMessage') + '...'} onKeyDown={onMessageKeyDown}
                              onKeyUp={onMessageKeyUp} value={message}
                              onChange={e => setMessage(e.target.value)}></textarea>
                    <FileDropField onDrop={onFilesSelected}/>
                </div>

                <div className="actions">
                    <UploadField onSelect={onFilesSelected}/>

                    <button onClick={send} title={t('chat.sendMessage')} className={sendButtonClasses.join(' ')}>
                        <span className="material-symbols-sharp">send</span>
                    </button>
                </div>
            </div>
        </div>
    )
}

export default MessageComposeField
