import React, {ChangeEvent, createRef} from "react";
import {useTranslation} from "react-i18next";

type Props = {
    onSelect: (files: FileList) => void,
}

const UploadField = ({ onSelect }: Props) => {
    const field = createRef<HTMLInputElement>()

    const { t } = useTranslation()

    const trigger = () => field.current?.click()

    const fileSelected = (e: ChangeEvent<HTMLInputElement>) => {
        const files = e.target.files
        if (files) {
            onSelect(files)
        }
    }

    return (
        <div className="upload">
            <input type="file" style={{ display: 'none' }} ref={ field } onChange={ fileSelected } multiple={true}/>
            <button onClick={ trigger } title={ t('chat.uploadFile') }>
                <span className="material-symbols-sharp">photo_library</span>
            </button>
        </div>
    )
}

export default UploadField
