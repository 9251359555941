import React, {createContext, useEffect, useRef, useState} from 'react';
import './App.scss';
import useWSClient, {ClientListEntry, Message, MessageFileObject, MessageRequest} from "./ws/WSClient";
import 'material-symbols'
import UserList from "./components/UserList";
import MessageView from "./components/MessageView";
import Lobby from "./components/Lobby";
import MessageComposeField from "./components/MessageComposeField";
import {FileQueueEntry} from "./components/FileQueue";
import {encrypt, useWebRTCHandler} from "./lib";
import {useDispatch} from "react-redux";
import {useTranslation} from "react-i18next";
import VideoChat, {RTCStatus} from "./components/VideoChat";
import BurgerMenu from "./components/BurgerMenu";
import SignatureRequest from "./components/SignatureRequest";
import {useAppDispatch} from "./store/hooks";
import SignatureLayer from "./components/SignatureLayer";
import AppContext from "./context/AppContext";
import ChatControlButton from "./components/chat/controls/ChatControlButton";
import MaterialIcon from "./components/icons/MaterialIcon";
import MaterialToggleIcon from "./components/icons/MaterialToggleIcon";
import LanguageSwitch from "./components/general/LanguageSwitch";
import SVGToggleIcon from "./components/icons/SVGToggleIcon";
import SVGIcon from "./components/icons/SVGIcon";

type Context = {
  id: string,
  room: string,
  email: string,
  name: string,
  users: ClientListEntry[],
  status: RTCStatus|null,
  stream: MediaStream|null,
}

function App() {
  const [ context, setContext ] = useState<Context>({
    id: '',
    room: '',
    email: '',
    name: '',
    users: [],
    status: null,
    stream: null,
  })

  const dispatch = useDispatch()
  const appDispatch = useAppDispatch()

  const [ chatStarted, setChatStarted ] = useState<boolean>(false)
  const [ chatRunning, setChatRunning ] = useState<boolean>(false)
  const [ connected, setConnected ] = useState<boolean>(false)

  const [ loading, setLoading ] = useState<boolean>(true)
  const [ error, setError ] = useState<string|null>(null)

  const [ forceVideoFrameShown, setForceVideoFrameShown ] = useState(false)
  const [ videoFrameShown, setVideoFrameShown ] = useState(false)
  const [ sidebars, setSidebars ] = useState<{ [key: string]: boolean }>({ left: false, right: false })

  const [ unreadMessageCount, setUnreadMessageCount ] = useState<number>(0)

  const client = useWSClient()

  const webRTCHandler = useWebRTCHandler()

  const { t, i18n } = useTranslation();

  const toggleSide = (side: string) => {
    setSidebars({
      ...sidebars,
      [side]: !sidebars[side]
    })
  }

  const startChat = (room: string, email: string, name: string, status: RTCStatus, stream: MediaStream) => {
    if (name) {
      const newContext = {
        id: '',
        room,
        email,
        name,
        users: [],
        status,
        stream,
      }

      setContext(newContext)
    }
  }

  const sendMessage = async (message: string, files: FileQueueEntry[]) => {
    setError(null)

    const request: MessageRequest = {
      room: context.room,
      id: context.id,
      files: files.map(f => ({
        name: f.file.name,
        type: f.file.type,
        size: f.file.size,
        data: f.dataUri,
        signatureRequested: false,
      })),
      rcpts: await Promise.all(context.users.map(async u => {
        return {
          id: u.id,
          data: await encrypt(message, u.pubkey)
        }
      }))
    }

    await Promise.all([
      client.sendMessage(request),
      client.send('typing', {
        room: context.room,
        id: context.id,
        typing: false,
      })
    ])
  }

  const onTypeStateChange = (typing: boolean) => {
    client.send('typing', {
      room: context.room,
      id: context.id,
      typing,
    })
  }

  useEffect(() => {
    if (context.name && !chatStarted) {
      client.onReady((id: string) => {
        context.id = id
        setContext(context)
        setChatRunning(true)
        setConnected(true)
        console.log('set ready')
      })

      client.onDisconnect(() => {
        setConnected(false)
      })

      client.onError((error: string) => {
        setError(error)
      })

      client.onMessage((message: Message) => {
        if (message.action === 'clients') {
          // @ts-ignore
          setContext({
            ...context,
            users: message.clients,
          })
          return
        }

        if (!sidebars.right && message.action === 'message') {
          setUnreadMessageCount(unreadMessageCount + 1)
        }

        dispatch({ type: 'messages/add', payload: message })
      })

      client.onFileUrl((message: string, file: MessageFileObject) => {
        dispatch({ type: 'files/add', payload: { message, file } })
      })

      client.onHistory((history: Message[]) => {
        console.log('got history in component')
        setLoading(false)
        dispatch({ type: 'messages/set', payload: history })
      })

      client.login(context.room, context.email, context.name)
      setChatStarted(true)

      setTimeout(() => startVideo(), 500)

      // webRTCHandler.onCall((callee: string) => {
      //   console.log(`new call from ${callee}`)
      // })
    }
  }, [context])

  let userNames = context.users.filter(u => u.id !== context.id).map(u => u.name)
  const chatNames = userNames.length
    ? t('chat.header', { names: userNames.join(', ') })
    : t('chat.noOtherUsers')

  let otherUser: ClientListEntry|null = null

  let other = context.users.filter(u => u.id !== context.id).pop()
  if (other !== undefined) {
    otherUser = other
  }

  const onVideoFrameVisibilityChange = (visible: boolean) => {
    setVideoFrameShown(visible)
    setForceVideoFrameShown(visible)
  }

  const startVideo = () => {
    webRTCHandler.init(context.stream, context.status)
    setForceVideoFrameShown(true)
  }

  const classNames = ['App']
  if (videoFrameShown) {
    classNames.push('video-shown')
  }
  if (videoFrameShown) {
    sidebars.left && classNames.push(`sidebar-left`)
    sidebars.right && classNames.push(`sidebar-right`)
  }

  useEffect(() => {
    if (sidebars.right) {
      setUnreadMessageCount(0)
    }
  }, [sidebars]);

  return (
      <div className={classNames.join(' ')}>
        <SignatureRequest context={ context }/>
        <SignatureLayer context={ context }/>

        {error &&
            <div className="error">{error}</div>}
        {chatRunning && (
            <div className="chat">
              <div className="left" style={{ display: !videoFrameShown || sidebars.left ? '' : 'none' }}>
                <header>
                  <div className="username">{ context.name }</div>
                </header>
                <UserList users={ context.users }/>
                <div className="status">
                  <div className="connected">
                    Verbunden: { connected ? 'ja' : 'nein' }
                  </div>
                </div>
              </div>

              <VideoChat
                  show={forceVideoFrameShown}
                  callee={otherUser}
                  client={client}
                  context={context}
                  onVisibleChange={onVideoFrameVisibilityChange}
                  contentLeft={(<div></div>)}
                  contentRight={(
                      <>
                        <ChatControlButton
                            onClick={() => toggleSide('right')}
                            title={t('videoControl.chat.show')}
                            activeTitle={t('videoControl.chat.hide')}
                            active={sidebars.right}
                            counter={unreadMessageCount}
                            icon={new SVGIcon('chat')}/>

                        { otherUser && (
                            <ChatControlButton
                              onClick={() => dispatch({ type: 'signature/startRequest'} )}
                              title={t('signature.requestSignature')}
                              icon={new SVGIcon('signature')}/>
                        )}
                      </>
                  )}
              />

              <div className="right" style={{ display: !videoFrameShown || sidebars.right ? '' : 'none' }}>
                <header>
                  <div className="left">
                    <h1 className="title">
                      { t('chat.headline') }
                    </h1>
                  </div>

                  <div className="right">
                    <div className="inner">
                      <LanguageSwitch/>

                      <a href="#" onClick={() => toggleSide('right')} className="close" title={t('videoControl.chat.hide')}>
                        {new MaterialIcon('close', MaterialIcon.STYLE_OUTLINED).get()}
                      </a>

                      {/*{!videoFrameShown && otherUser && <a href="#" onClick={startVideo} title="Video">*/}
                      {/*  <span className="material-symbols-sharp">videocam</span>*/}
                      {/*</a>}*/}
                    </div>
                  </div>
                </header>
                {loading
                    ? (
                        <div className="messages loading">
                          <span className="material-symbols-sharp">hourglass_top</span>
                        </div>
                    )
                    : <MessageView context={ context }/> }

                <MessageComposeField onSendMessage={ sendMessage } onTypeStateChange={ onTypeStateChange }/>
              </div>
            </div>
        ) || (
            <Lobby onStartChat={ startChat }/>
        )}
      </div>
  );
}

export {
  App as default,
  Context,
};
