import React, {createRef, useEffect, useState} from "react";

type Props = {
    onDrop: (files: FileList) => void
}

const FileDropField = ({ onDrop }: Props) => {
    const [ hasDragOver, setHasDragOver] = useState<boolean>(false)

    const uploadField = createRef<HTMLDivElement>()

    useEffect(() => {
        let counter = 0
        const bodyDragOver = (e: DragEvent) => {
            e.preventDefault()
            document.body.classList.add('drag-over')
            counter ++
        }
        const bodyDragLeave = (e: DragEvent) => {
            e.preventDefault()
            counter --
            if (counter === 0) {
                document.body.classList.remove('drag-over')
            }
        }
        const bodyDragEnd = (e: DragEvent) => {
            e.preventDefault()
            e.stopPropagation()

            document.body.classList.remove('drag-over')
            setHasDragOver(false)

            counter = 0
        }
        const bodyDrop = (e: DragEvent) => {
            e.preventDefault()
            e.stopPropagation()

            document.body.classList.remove('drag-over')
            setHasDragOver(false)

            counter = 0

            let dt = e.dataTransfer
            let files = dt?.files

            if (files) {
                onDrop(files)
            }
        }

        document.body.addEventListener('dragenter', bodyDragOver)
        document.body.addEventListener('dragleave', bodyDragLeave)
        document.body.addEventListener('dragend',  bodyDragEnd)
        document.body.addEventListener('drop',  bodyDragEnd)

        document.body.ondragover = function (e) {
            e.preventDefault()
        }

        uploadField.current?.addEventListener('dragenter', () => setHasDragOver(true))
        uploadField.current?.addEventListener('dragleave', () => setHasDragOver(false))
        uploadField.current?.addEventListener('drop', bodyDrop)
    }, [])

    const uploadFieldClasses = ['drop-target']
    if (hasDragOver) {
        uploadFieldClasses.push('drag-over')
    }

    return (
        <div className={ uploadFieldClasses.join(' ') } ref={uploadField}>
            {!hasDragOver
                ? 'Ziehen Sie die Datei hier hin'
                : 'Lassen Sie die Datei jetzt los'
            }
        </div>
    )
}

export default FileDropField
