import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import store from './store';
import { Provider } from 'react-redux'
// import reportWebVitals from './reportWebVitals';

import './i18n'

process.env.NODE_TLS_REJECT_UNAUTHORIZED = "0";

const update = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
}

update()
window.addEventListener('resize', update)
window.addEventListener('beforeunload', (e) => {
    e.preventDefault()
    e.stopPropagation()

    console.log('update')

    return 'Möchten Sie den Chat wirklich beenden?'
})

// const registerServiceWorker = async () => {
//     if ("serviceWorker" in navigator) {
//         try {
//             const registration = await navigator.serviceWorker.register("/sw.js", {
//                 scope: "/",
//             });
//             if (registration.installing) {
//                 console.log("Service worker installing");
//             } else if (registration.waiting) {
//                 console.log("Service worker installed");
//             } else if (registration.active) {
//                 console.log("Service worker active");
//             }
//         } catch (error) {
//             console.error(`Registration failed with ${error}`);
//         }
//     }
// };
//
// registerServiceWorker()


// @ts-ignore
const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <Provider store={store}>
        <App />
    </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
