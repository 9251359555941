import React, {useEffect, useRef, useState} from "react";
import {parseHash} from "../lib";
import {StreamConfig, useDeviceManager} from "../lib/DeviceManager";
import './Lobby.scss'
import VideoControlButtons from "./chat/VideoControlButtons";
import MaterialIcon from "./icons/MaterialIcon";
import ChatControlButton from "./chat/controls/ChatControlButton";
import {useTranslation} from "react-i18next";
import SVGIcon from "./icons/SVGIcon";
import {unstable_renderSubtreeIntoContainer} from "react-dom";
import LanguageSwitch from "./general/LanguageSwitch";
import InpritonLogo from "./general/InpritonLogo";
import VolumeMeter from "../lib/VolumeMeter";
import Visualization from "./general/Visualization";
import SVGToggleIcon from "./icons/SVGToggleIcon";
import {RTCStatus} from "./VideoChat";

type Props = {
    onStartChat: (room: string, email: string, username: string, status: RTCStatus, stream: MediaStream|null) => void
}

const Lobby = ({ onStartChat }: Props) => {
    const {t} = useTranslation()

    const [ room, setRoom ] = useState<string>('')
    const [ email, setEmail ] = useState<string>('')
    const [ username, setUsername ] = useState<string>('')
    const [ hasError, setHasError ] = useState<boolean>(false)

    const [ hashRead, setHashRead ] = useState<boolean>(false)

    const [ devices, setDevices ] = useState<MediaDeviceInfo[]>([])

    const [ permissionDenied, setPermissionDenied ] = useState<boolean>(false)

    const [ volumeMeter, setVolumeMeter ] = useState<VolumeMeter|null>(null)
    const [ volume, setVolume ] = useState<number>(0)

    const [ stream, setStream ] = useState<MediaStream|null>(null)

    const [ status, setStatus ] = useState<RTCStatus|null>(null)

    const videoRef = useRef<HTMLVideoElement>()
    const controlsRef = useRef<HTMLDivElement>()
    const lobbyRef = useRef<HTMLDivElement>()
    const nameRef = useRef<HTMLInputElement>()

    const deviceManager = useDeviceManager()

    useEffect(() => {
        deviceManager
            .onDevices(devices => {
                setDevices(devices)
            })
            .onStream(stream => {
                setStream(stream)
                if (videoRef.current) {
                    videoRef.current.srcObject = stream
                }
                setPermissionDenied(false)
            })
            .onPermissionDenied(() => {
                setPermissionDenied(true)
            })
            .onVolumeMeter(setVolumeMeter)
            .onConfigChange(setStatus)
            .start()
    },[])

    const onCameraChange = (e: any) => {
        deviceManager.setCameraId(e.target.value)
    }

    const onMicrophoneChange = (e: any) => {
        deviceManager.setMicrophoneId(e.target.value)
    }

    const startChat = () => {
        if (stream && status && !permissionDenied) {
            onStartChat(room, email, username, status, stream)
        }
    }

    const onUsernameFieldKeyUp = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key.toLowerCase() === 'enter') {
            startChat()
        }
    }

    const loadHash = () => {
        const data = parseHash()

        let hasError = false
        for (let key of ['room', 'email']) {
            if (typeof data[key] === 'undefined') {
                hasError = true
                break
            }
        }

        if (typeof data['name'] !== 'undefined') {
            setUsername(decodeURIComponent(data['name']))
        }

        if (hasError) {
            setHasError(true)
        } else {
            setRoom(data.room)
            setEmail(data.email)

            setHasError(false)
        }

        setHashRead(true)
    }

    useEffect(() => {
        nameRef.current?.focus()
        loadHash()
    }, []);

    useEffect(() => {
        if (volumeMeter) {
            const updateVolume = () => {
                const value = volumeMeter.getVolume()
                setVolume(value)
            }

            const interval = setInterval(updateVolume, 50)

            return () => clearInterval(interval)
        }
    }, [volumeMeter]);

    const setAudio = (enabled: boolean) => {
        deviceManager.setAudioEnabled(enabled)
    }

    const setVideo = (enabled: boolean) => {
        deviceManager.setVideoEnabled(enabled)
    }

    const onVideoError = (e: any) => {
        alert(e.toString())
    }

    const cameras = devices.filter(d => d.kind === 'videoinput')
    const microphones = devices.filter(d => d.kind === 'audioinput')

    window.addEventListener('hashchange', loadHash)

    const lobbyClassNames = ['lobby']
    if (permissionDenied) {
        lobbyClassNames.push('permission-denied')
    }

    return (
        <div className="lobby-container">
            <header>
                <div className="inner">
                    <div className="left">
                        <div className="logo">
                            <InpritonLogo/>
                        </div>
                    </div>
                    <div className="right">
                        <LanguageSwitch/>
                    </div>
                </div>
            </header>

            <div className={lobbyClassNames.join(' ')} ref={lobbyRef}>
                {hasError
                    ? (
                        <>
                            Ungültige Adresse. Bitte überprüfen Sie diese erneut.
                        </>
                    )
                    : (
                        <div className="inner">
                            <div className="left">
                                <div className="inner">
                                    <div className="preview-video-container">
                                        <video
                                            ref={videoRef}
                                            playsInline
                                            autoPlay
                                            muted
                                            onError={onVideoError}
                                            style={{ display: permissionDenied ? 'none' : 'block'}}></video>
                                        <span>
                                            {permissionDenied ? (
                                                <>{t('lobby.noPermission')}</>
                                            ) : (
                                                <>{t('lobby.loading')}&hellip;</>
                                            )}
                                        </span>

                                        <ul className="buttons">
                                            <li>
                                                <ChatControlButton
                                                    onClick={() => setVideo(!(status?.videoEnabled))}
                                                    title={t('lobby.button.video.enable')}
                                                    activeTitle={t('lobby.button.video.disable')}
                                                    hasWarning={permissionDenied}
                                                    disabled={permissionDenied}
                                                    active={status?.videoEnabled}
                                                    icon={new SVGToggleIcon('video-off', 'video')}/>
                                            </li>
                                            <li>
                                                <ChatControlButton
                                                    onClick={() => setAudio(!(status?.audioEnabled))}
                                                    title={t('lobby.button.microphone.enable')}
                                                    activeTitle={t('lobby.button.microphone.disable')}
                                                    hasWarning={permissionDenied}
                                                    disabled={permissionDenied}
                                                    active={status?.audioEnabled}
                                                    icon={new SVGToggleIcon('microphone-off', 'microphone')}/>
                                            </li>
                                            <li>
                                                <ChatControlButton
                                                    onClick={() => lobbyRef.current?.classList.toggle('controls')}
                                                    title={t('lobby.button.settings')}
                                                    icon={new SVGIcon('settings')}/>
                                            </li>
                                        </ul>
                                    </div>
                                    <ul className="controls" ref={controlsRef}>
                                        <li>
                                            <label htmlFor="sel-camera">{t('lobby.camera')}</label>

                                            <select onChange={onCameraChange} id="sel-camera">
                                                {cameras.map(camera => (
                                                    <option key={camera.deviceId} value={camera.deviceId}>
                                                        {camera.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </li>

                                        <li>
                                            <label htmlFor="sel-microphone">{t('lobby.microphone')}</label>

                                            <div className="audio-select">
                                                <select onChange={onMicrophoneChange} id="sel-microphone">
                                                    {microphones.map(microphone => (
                                                        <option key={microphone.deviceId} value={microphone.deviceId}>
                                                            {microphone.label}
                                                        </option>
                                                    ))}
                                                </select>
                                                <Visualization max={10} value={volume}/>
                                            </div>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                            <div className="right">
                                <div className="inner">
                                    <div className="box data">
                                        <label htmlFor="name">{t('lobby.yourName')}</label>

                                        <div className="name">
                                            <input type="text" placeholder="" onKeyUp={onUsernameFieldKeyUp} id="name"
                                                   ref={nameRef}
                                                   value={username}
                                                   disabled={permissionDenied}
                                                   onChange={e => setUsername(e.target.value)}/>
                                        </div>
                                    </div>
                                    <div className="buttons">
                                        <button onClick={startChat} disabled={permissionDenied}>
                                            {t('lobby.enterNow')}
                                        </button>
                                        <button>
                                            {t('lobby.leave')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
            </div>
        </div>
    )
}

export default Lobby
