import {MessageFileObject, UserMessage} from "../ws/WSClient";
import React, {ReactComponentElement} from "react";
import {createBadge, formatDate, parseMessage} from "../lib";
import {Context} from "../App";
import FileList from "./FileList";
import {useSelector} from "react-redux";
import {useAppSelector} from "../store/hooks";
import {useTranslation} from "react-i18next";
import UserBadge from "./general/UserBadge";

type Props = {
    id: string,
    message: UserMessage,
    context: Context
}

const SingleMessage = ({ id, message, context }: Props) => {
    const { t } = useTranslation()

    const classnames = ['message']

    const files = useAppSelector(state => typeof state.files.files[message.id] !== 'undefined' ? state.files.files[message.id] : [])

    const allFiles = typeof message.files !== 'undefined' ? message.files.map(f => ({ ...f })) : []

    if (files.length) {
        let found = false
        for (const newFile of files) {
            for (let i in allFiles) {
                if (allFiles[i].name === newFile.name) {
                    allFiles[i].url = newFile.url
                    allFiles[i].signedUrl = newFile.signedUrl
                    found = true
                    break
                }
            }
            if (!found) {
                allFiles.push(newFile)
            }
        }
    }

    if (message.sender === context.id) {
        classnames.push('self')
    }

    return (
        <>
            <div className={classnames.join(' ')} key={id}>
                <div className="left">
                    <UserBadge name={message.senderName}/>
                </div>
                <div className="base">
                    <div className="meta">
                        <span className="from">{ classnames.includes('self') ? t('you') : message.senderName }</span>
                        <span className="timestamp">{typeof message.timestamp !== 'undefined' ? formatDate(message.timestamp).split(' ')[1] : ''}</span>
                    </div>
                    { message.message && (
                        <div className="content"
                             dangerouslySetInnerHTML={{__html: parseMessage(message.message.replace('[encryption_failed]', t('chat.encryptionFailed')))}}
                             style={{overflowX: 'auto'}}/>
                    )}
                    {files && (
                        <FileList files={allFiles} senderName={message.senderName}
                                  isSender={message.sender === context.id}/>
                    )}
                </div>
            </div>
        </>
    )
}

export default SingleMessage
