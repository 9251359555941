const VolumeMeter = function(audioOrStream, shouldStillPlayAudio){

    var _this = this;
    var streamData = new Uint8Array(128);

    window.VolumeMeterAudioContext = window.VolumeMeterAudioContext || new (window.AudioContext || window.webkitAudioContext);
    this._audioCtx = window.VolumeMeterAudioContext;
    this._analyser = this._audioCtx.createAnalyser();
    this._analyser.fftSize = 256;
    this._analyser.smoothingTimeConstant = 0.2;

    if (audioOrStream.constructor.name == 'HTMLAudioElement')
        _this._source = this._audioCtx.createMediaElementSource(audioOrStream);
    if (audioOrStream.constructor.name == 'MediaStream')
        _this._source = this._audioCtx.createMediaStreamSource(audioOrStream);

    _this._source.connect(this._analyser);
    if (shouldStillPlayAudio)
        this._analyser.connect(this._audioCtx.destination);



    /*	Methods	*/
    this.getVolume = function() {
        _this._analyser.getByteFrequencyData(streamData);
        var total = 0;
        for (var i = 0; i < streamData.length; i++)
            total += streamData[i];
        return total/1000;
    };

    this.stop = function(){
        _this._analyser.disconnect()
        _this._source.disconnect();
    }
}

export default VolumeMeter