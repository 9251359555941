import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import en from './en';
import de from './de';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        lng: 'de',
        resources: {
            de,
            en,
        },
        fallbackLng: "de",
        debug: true,

        // have a common namespace used around the full app
        ns: ["translations"],
        defaultNS: "translations",

        interpolation: {
            escapeValue: false
        }
    });

export default i18n;
