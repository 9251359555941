import {Message, MessageFileObject} from "../ws/WSClient";
import React, {createRef} from "react";
import {Context} from "../App";
import SingleMessage from "./SingleMessage";
import {useSelector} from "react-redux";
import {useAppSelector} from "../store/hooks";
import {useTranslation} from "react-i18next";

type Props = {
    context: Context,
}

const MessageView = ({ context }: Props) => {
    const messageEndRef = createRef<HTMLDivElement>()

    const { t } = useTranslation()

    const messages = useAppSelector(state => state.messages.messages)

    const list = messages.map((message, i) => {
        switch (message.action) {
            case 'message':
                return <SingleMessage message={message} context={context} id={'' + i} key={message.id}/>
            case 'joined':
                return <></>
                /*
                return (
                    <div className="notice" key={i}>
                        { message.id === context.id
                            ? <></>
                            : <div className="content" dangerouslySetInnerHTML={{ __html: t('chat.userJoined', { name: message.name }) }}></div> }
                    </div>
                )
                */
            case 'left':
                return <></>
                /*
                return (
                    <div className="notice" key={i}>
                        { message.id === context.id
                            ? <></>
                            : <div className="content" dangerouslySetInnerHTML={{ __html: t('chat.userLeft', { name: message.name }) }}></div> }
                    </div>
                )
                */
        }
        return null;
    }).filter(m => !!m)

    setTimeout(() => {
        messageEndRef.current?.scrollIntoView()
    })

    const typingUsers = context.users.filter(u => u.id !== context.id && u.typing)

    let typingMessage = null
    if (typingUsers.length) {
        const lastUser = typingUsers.pop()

        typingMessage = t('chat.typing', {
            count: typingUsers.length + 1,
            names: typingUsers.map(u => u.name),
            name: lastUser?.name,
        })
    }

    return (
        <div className="messages">
            <div className="inner">
                { list }
                <div ref={messageEndRef}/>
            </div>
            { typingMessage && (
                <div className="typing" dangerouslySetInnerHTML={{ __html: typingMessage }}/>
            )}
        </div>
    )
}

export default MessageView
