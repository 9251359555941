import VolumeMeter from "./VolumeMeter";
import {RTCStatus} from "../components/VideoChat";

export type StreamConfig = {
    video: boolean
    audio: boolean
}

class DeviceManager
{
    private stream: MediaStream|null = null

    private devices: MediaDeviceInfo[] = []

    private cameraId: string = ''
    private microphoneId: string = ''

    private videoEnabled = true
    private audioEnabled = true

    private permissionStatus: boolean|null = null

    private callbacks: { [key: string]: Function[] } = {
        onDevices: [],
        onStream: [],
        onPermissionUpdate: [],
        onPermissionDenied: [],
        onVolumeMeter: [],
        onConfigChange: [],
    }

    constructor() {
        navigator.mediaDevices.enumerateDevices()
            .then((devices) => this.gotDevices(devices))
            .catch((error) => this.handleError(error))
    }

    public start() {
        if (this.stream) {
            this.stream.getTracks().forEach(track => {
                console.log('stopping track', track)
                track.stop()
            })
        }

        const constraints = {
            audio: { deviceId: this.microphoneId.length ? { exact: this.microphoneId } : undefined },
            video: { deviceId: this.cameraId.length ? { exact: this.cameraId } : undefined },
        }
        console.log('constraints', constraints)

        navigator.mediaDevices.getUserMedia(constraints)
            .then((stream) => this.gotStream(stream))
            .then((devices) => this.gotDevices(devices))
            .catch((error) => this.handleError(error))

        this.fireConfig()
    }

    public onDevices(cb: (devices: MediaDeviceInfo[]) => void): DeviceManager {
        this.callbacks.onDevices.push(cb)
        return this
    }

    public onStream(cb: (stream: MediaStream|null) => void): DeviceManager {
        this.callbacks.onStream.push(cb)
        return this
    }

    public onVolumeMeter(cb: (meter: VolumeMeter|null) => void): DeviceManager {
        this.callbacks.onVolumeMeter.push(cb)
        return this
    }

    public onPermissionDenied(cb: () => void): DeviceManager {
        this.callbacks.onPermissionDenied.push(cb)
        return this
    }

    public onPermissionUpdate(cb: (granted: boolean) => void): DeviceManager {
        this.callbacks.onPermissionUpdate.push(cb)
        return this
    }

    public onConfigChange(cb: (config: RTCStatus) => void): DeviceManager {
        this.callbacks.onConfigChange.push(cb)
        return this
    }

    private gotDevices(devices: MediaDeviceInfo[]) {
        this.callbacks.onDevices.forEach(cb => cb(devices))
    }

    private async gotStream(stream: MediaStream) {
        this.stream = stream
        this.callbacks.onStream.map(cb => cb(stream))

        const meter = new VolumeMeter(stream)
        this.callbacks.onVolumeMeter.map(cb => cb(meter))

        return navigator.mediaDevices.enumerateDevices()
    }

    public getDevices(): MediaDeviceInfo[] {
        return this.devices
    }

    public setCameraId(id: string) {
        this.cameraId = id
        this.start()
    }

    public setMicrophoneId(id: string) {
        this.microphoneId = id
        this.start()
    }

    public getStream(): MediaStream|null {
        return this.stream
    }

    private setPermissionStatus(granted: boolean) {
        this.permissionStatus = granted

        this.callbacks.onPermissionUpdate.map(cb => cb())

        if (!granted) {
            this.callbacks.onPermissionDenied.map(cb => cb())
        }
    }

    public getPermissionStatus(): boolean|null {
        return this.permissionStatus
    }

    public setVideoEnabled(enabled: boolean) {
        this.videoEnabled = enabled
        this.stream?.getVideoTracks().map(track => track.enabled = enabled)
        this.fireConfig()
    }

    public setAudioEnabled(enabled: boolean) {
        this.audioEnabled = enabled
        this.stream?.getAudioTracks().map(track => track.enabled = enabled)
        this.fireConfig()
    }

    private fireConfig() {
        const status: RTCStatus = {
            videoEnabled: this.videoEnabled,
            audioEnabled: this.audioEnabled,
            screenShareEnabled: false,
            state: ''
        }

        this.callbacks.onConfigChange.map(cb => cb(status))
    }

    private handleError(error: any) {
        if (
            error.toString().toLowerCase().includes('permission denied') ||
            error.toString().toLowerCase().includes('not allowed')
        ) {
            this.setPermissionStatus(false)
        }
        console.error(error)
    }
}

let instance = new DeviceManager()

const useDeviceManager = () => {
    return instance
}

export {
    useDeviceManager
}