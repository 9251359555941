import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {MessageFileObject} from "../../ws/WSClient";

interface SignatureState {
    showOutgoing: boolean
    fileToSign: MessageFileObject|null
}

const initialState: SignatureState = {
    showOutgoing: false,
    fileToSign: null
}

const signatureSlice = createSlice({
    name: 'signature',
    initialState,
    reducers: {
        startRequest: (state, action: PayloadAction<void>) => {
            state.showOutgoing = true
        },

        cancelRequest: (state) => {
            state.showOutgoing = false
        },

        startSigning: (state, action: PayloadAction<MessageFileObject>) => {
            state.fileToSign = action.payload
        },

        cancelSigning: (state) => {
            state.fileToSign = null
        },
    }
})

export default signatureSlice.reducer
