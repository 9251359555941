import React from 'react'
import {isImageType, shortenFileName} from "../../lib";

import './FilePreview.scss'

interface Props {
    loading: boolean,
    name: string,
    data?: {
        type: string,
        dataUri: string,
    } | null,
    children: any
}

const FilePreview = ({ loading, name, data = null, children }: Props) => {
    return loading ? (
        <div className="preview loading">
            <div className="symbol">
                <span className="material-symbols-sharp">hourglass_top</span>
            </div>
            <div className="filename" title={ name }>
                { shortenFileName(name) }
            </div>
        </div>
    ) : (
        <div className="preview">
            { children }
            { isImageType(data?.type)
                ? <img src={data?.dataUri } alt={ name }/>
                : <div className="symbol">
                    <span className="material-symbols-sharp">description</span>
                </div> }

            <div className="filename" title={ name }>
                { shortenFileName(name) }
            </div>
        </div>
    )
}

export default FilePreview
