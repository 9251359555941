import React from "react";
import SVGIcon from "./SVGIcon";

export default class SVGToggleIcon implements IconInterface
{
    private name: string;
    private activeName: string;

    constructor(name: string, activeName: string) {
        this.name = name;
        this.activeName = activeName;
    }

    get(active: boolean = false): React.ReactNode {
        if (active) {
            return new SVGIcon(this.activeName).get()
        }
        return new SVGIcon(this.name).get()
    }
}