import {createKeys, dataURItoBlob, decrypt, encrypt} from "../lib";

type ClientListEntry = {
    id: string
    name: string
    typing: boolean
    pubkey: string
}

type MessageFileObject = {
    id: string,
    name: string,
    type: string,
    size: number,
    url: string|null,
    signatureRequested: boolean,
    signedUrl: string,
}

type UserMessage = {
    action: 'message',
    id: string,
    sender: string,
    senderName: string,
    message: string,
    timestamp: number,
    files?: MessageFileObject[]
}

type UserJoinedMessage = {
    action: 'joined',
    id: string,
    name: string,
    timestamp: number,
}

type UserLeftMessage = {
    action: 'left',
    id: string,
    name: string[],
    timestamp: number,
}

type ClientsMessage = {
    action: 'clients',
    clients: ClientListEntry[],
}

type FileRequestMessageObject = {
    id: string
    name: string
    rcpt: string
    size: number
    type: string
    url: string
    signatureRequested: boolean
}

type FileRequestMessage = {
    action: 'filerequests',
    messageId: string,
    rcpt: string,
    files: FileRequestMessageObject[]
}

type FileUrlResponse = {
    action: 'fileurl'
    message: string
    file: MessageFileObject
}

type Message = UserMessage | UserJoinedMessage | UserLeftMessage | ClientsMessage

type Callbacks = {
    onMessage: ((message: Message) => void)[]
    onHistory: ((history: Message[]) => void)[]
    onReady: ((id: string) => void)[]
    onError: ((error: string) => void)[]
    onDisconnect: (() => void)[]
    onFileRequest: ((request: FileRequestMessage) => void)[]
    onFileUrl: ((message: string, file: MessageFileObject) => void)[]
    onWebRTCAction: ((data: { [key: string]: any }) => void)[]
}

type MessageAttachmentInfo = {
    name: string,
    type: string,
    size: number,
}

// --------------

type MessageRequestFile = {
    name: string
    type: string
    size: number
    signatureRequested: boolean,
    data?: string
}

type MessageRequestRcpt = {
    id: string
    data: string
}

type MessageRequest = {
    room: string
    id: string
    files: MessageRequestFile[]
    rcpts: MessageRequestRcpt[]
}

type SignedPDFRequest = {
    room: string
    id: string
    url: string
    content: string
}

// --------------

class WSClient
{
    private readonly url: string;

    private room: string|null = null;
    private roomUuid: string|null = null;
    private email: string|null = null;
    private name: string|null = null;

    private ws: WebSocket|null = null;
    private connected: boolean = false

    private keys: { privateKey: string, publicKey: string}|null = null

    private fileQueue: MessageRequestFile[] = []

    private callbacks: Callbacks = {
        onMessage: [],
        onHistory: [],
        onReady: [],
        onError: [],
        onDisconnect: [],
        onFileRequest: [],
        onFileUrl: [],
        onWebRTCAction: [],
    }

    private decryptor: Worker;

    private messageCache: Message[] = []
    private historyReceived = false

    constructor(url: string) {
        this.url = url
        this.connect()

        this.decryptor = new Worker('/encryptor.js')
    }

    async login(number: string|null = null, email: string|null = null, name: string|null = null) {
        if (!this.keys && number && email && name) {
            const savedKeys = localStorage.getItem('chat-keys-' + number)
            if (savedKeys) {
                this.keys = JSON.parse(savedKeys)
            } else {
                this.keys = await createKeys(name, email)
                localStorage.setItem('chat-keys-' + number, JSON.stringify(this.keys))
            }

            this.decryptor.postMessage({ keys: this.keys })
        }

        if (number !== this.room) {
            this.room = number
        }
        if (email !== this.email) {
            this.email = email
        }
        if (name !== this.name) {
            this.name = name
        }

        this.send('login', { number, email, name, pubkey: this.keys?.publicKey })
    }

    connect() {
        const doConnect = () => {
            const ws = new WebSocket(this.url)

            ws.addEventListener('open', () => {
                this.connected = true
                console.log('connected to websocket')

                if (this.name) {
                    this.login(this.room, this.email, this.name)
                }
            })

            ws.addEventListener('message', async (e) => {
                try {
                    const data = JSON.parse(e.data)
                    data.timestamp = (new Date()).getTime()

                    console.log('got message', data)

                    // loggedin
                    if (typeof data['action'] !== 'undefined' && data['action'] === 'loggedin' && typeof data['id'] !== 'undefined' && data['id']) {
                        this.roomUuid = data['room']
                        this.callbacks.onReady.forEach(cb => cb(data['id']))
                        return
                    }

                    // error
                    if (typeof data['error'] !== 'undefined' && data['error']) {
                        this.callbacks.onError.forEach(cb => cb(data['error']))
                        return
                    }

                    // fileurl
                    if (typeof data['action'] !== 'undefined' && data['action'] === 'fileurl' && typeof data['file'] !== 'undefined' && data['file']) {
                        this.callbacks.onFileUrl.forEach(cb => cb(data['message'], data['file']))
                        return
                    }

                    // webrtc
                    if (typeof data['action'] !== 'undefined' && data['action'] === 'webrtc') {
                        this.callbacks.onWebRTCAction.forEach(cb => cb(data))
                        return
                    }

                    // history
                    if (typeof data['action'] !== 'undefined' && data['action'] === 'history' && typeof data['history'] !== 'undefined' && data['history']) {
                        this.decryptHistory(data.history)
                            .then(() => {
                                this.historyReceived = true
                                this.messageCache.forEach(message =>
                                    this.callbacks.onMessage.forEach(cb => cb(message)))
                            })
                        return
                    }

                    // history
                    if (typeof data['action'] !== 'undefined' && data['action'] === 'filerequest') {
                        this.handleFileRequest(data)
                        return
                    }

                    // message
                    if (typeof data['action'] !== 'undefined' && data['action'] === 'message' && typeof data['message'] !== 'undefined' && data['message']) {
                        try {
                            data['message'] = await decrypt(data['message'], this.keys?.privateKey)
                        } catch (error) {
                            data['message'] = '<i>Entschlüsselung fehlgeschlagen</i>'
                        }
                    }

                    if (this.historyReceived) {
                        this.callbacks.onMessage.forEach(cb => cb(data))
                    } else {
                        this.messageCache.push(data)
                    }

                } catch {}
            })

            ws.addEventListener('close', () => {
                if (this.connected) {
                    this.callbacks.onDisconnect.forEach(cb => cb())
                    this.connected = false
                }
            })

            ws.addEventListener('error', () => {
                console.debug('error from websocket')
                ws.close()
            })

            return ws
        }

        const handleDisconnect = () => {
            this.ws?.close()

            setTimeout(() => {
                this.ws = doConnect()
                this.ws.addEventListener('close', handleDisconnect)
            }, 1000)
        }

        this.ws = doConnect()
        this.ws.addEventListener('close', handleDisconnect)
    }

    async decryptHistory(encryptedHistory: Message[]) {
        let reply: any = null

        const handleReply = (e: any) => {
            reply = e.data.history
        }

        this.decryptor.addEventListener('message', handleReply)

        this.decryptor.postMessage({ history: encryptedHistory })

        return new Promise(resolve => {
            const check = () => {
                if (reply) {
                    this.decryptor.removeEventListener('message', handleReply)

                    this.callbacks.onHistory.forEach(cb => cb(reply))

                    resolve(reply)
                    return
                }
                setTimeout(check, 500)
            }
            check()
        })
    }

    handleFileRequest(request: { files: FileRequestMessageObject[] }) {
        request.files.forEach(async (file) => {
            const searchRes = this.fileQueue.filter(f => f.name === file.name && f.type === file.type && f.size === file.size)

            if (searchRes.length !== 1) {
                console.error('none or multiple files found', request)
            }

            console.log('got file request', file, searchRes[0])

            await fetch(file.url, {
                method: 'PUT',
                headers: {
                    'Content-Type': file.type,
                },
                // @ts-ignore
                body: await dataURItoBlob(searchRes[0].data)
            })

            await this.send('uploaded', {
                id: file.id,
            })
        })
    }

    async message(room: string, id: string, rcpt: string, key: string, message: string, files: MessageAttachmentInfo[] = []) {
        const encrypted = await encrypt(message, key)
        this.send('message', { room, id, rcpt, message: encrypted, files })
    }

    async decrypt(encrypted: string) {
        return await decrypt(encrypted, this.keys?.privateKey)
    }

    async sendMessage(request: MessageRequest) {
        request.files = request.files.map(f => {
            this.fileQueue.push(f)

            return {
                name: f.name,
                type: f.type,
                size: f.size,
                signatureRequested: f.signatureRequested,
            }
        })

        await this.send('message', request)
    }

    async send(action: string, data: object) {
        this.ws?.send(JSON.stringify({ action, ...data }))
    }

    onMessage(cb: (message: Message) => void) {
        this.callbacks.onMessage.push(cb)
    }

    onFileUrl(cb: (message: string, file: MessageFileObject) => void) {
        this.callbacks.onFileUrl.push(cb)
    }

    onWebRTCAction(cb: (data: { [key: string]: any }) => void) {
        this.callbacks.onWebRTCAction.push(cb)
    }

    onHistory(cb: (history: Message[]) => void) {
        this.callbacks.onHistory.push(cb)
    }

    onReady(cb: (id: string) => void) {
        this.callbacks.onReady.push(cb)
    }

    onError(cb: (error: string) => void) {
        this.callbacks.onError.push(cb)
    }

    onDisconnect(cb: () => void) {
        this.callbacks.onDisconnect.push(cb)
    }

    getRoom() {
        return this.room
    }

    getName() {
        return this.name
    }

    getEmail() {
        return this.email
    }

    getRoomUuid() {
        return this.roomUuid
    }
}

let client: WSClient|null = null

function useWSClient(): WSClient {
    if (!client) {
        console.debug('create new client', process.env.NODE_ENV)
        if (process.env.NODE_ENV !== 'production') {
            client = new WSClient('wss://i-chat-ws.loc.sfat.it')
        } else {
            client = new WSClient('wss://i-chat-ws.sftest.at')
        }
    }

    // @ts-ignore
    return client
}

export {
    useWSClient as default,
    WSClient,
    Message,
    UserMessage,
    MessageFileObject,
    ClientListEntry,
    MessageRequestFile,
    MessageRequestRcpt,
    MessageRequest,
    SignedPDFRequest,
}
