import {useTranslation} from "react-i18next";
import React from "react";

import './LanguageSwitch.scss'

const LanguageSwitch = () => {
    const { t, i18n } = useTranslation();

    const changeLanguage = (e: any, lng: string) => {
        e.preventDefault()
        i18n.changeLanguage(lng);
    };

    return (
        <ul className="lang">
            <li className={i18n.language === 'de' ? 'active' : ''}>
                <a href="#" onClick={(e) => changeLanguage(e, 'de')}>DE</a>
            </li>
            <li className={i18n.language === 'en' ? 'active' : ''}>
                <a href="#" onClick={(e) => changeLanguage(e, 'en')}>EN</a>
            </li>
        </ul>
    )
}

export default LanguageSwitch