import './Visualization.scss'

type Props = {
    max?: number
    value?: number
}

export default ({ max = 100, value = 0 }: Props) => {
    if (value > max) {
        value = max;
    }
    if (value < .1) {
        value = .1
    }

    const percent = value * 100 / max

    const innerStyle = {
        width: `${percent}%`
    }

    return (
        <div className="visualization">
            <div className="inner" style={innerStyle}></div>
        </div>
    )
}