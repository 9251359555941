import React from "react";

const InpritonLogo = () => {
    return (
        <svg viewBox="0 0 2835 882" height="882" width="2835" xmlns="http://www.w3.org/2000/svg"
             className="logo-default">
            <g xmlns="http://www.w3.org/2000/svg" id="Gruppe_813" data-name="Gruppe 813" transform="translate(-300 -4)">
                <g id="Logo" transform="translate(-121 -216)">
                    <circle id="Ellipse_56" data-name="Ellipse 56" cx="300" cy="300" r="300"
                            transform="translate(421 361)" fill="#1976d2"></circle>
                    <path id="Pfad_2129" data-name="Pfad 2129" d="M464.113,367.117v450h26.9v-450h-30"
                          transform="matrix(0.616, -0.788, 0.788, 0.616, 65.482, 795.7)" fill="#fff"></path>
                    <circle id="Ellipse_59" data-name="Ellipse 59" cx="37.5" cy="37.5" r="37.5"
                            transform="translate(961 886)" fill="#1976d2"></circle>
                    <circle id="Ellipse_61" data-name="Ellipse 61" cx="37.5" cy="37.5" r="37.5"
                            transform="translate(871 526)" fill="#fff"></circle>
                    <path id="Pfad_2127" data-name="Pfad 2127"
                          d="M439.971,377.47V633.82h60V361.87a131.685,131.685,0,0,0-14.669,2.4c-.9.157-8.071,1.65-15.33,3.6A238.046,238.046,0,0,0,439.971,377.47Z"
                          transform="translate(176.029 2.53)" fill="#fff"></path>
                    <circle id="Ellipse_60" data-name="Ellipse 60" cx="112.5" cy="112.5" r="112.5"
                            transform="translate(536 526)" fill="#fff"></circle>
                </g>
                <text id="inpriton" transform="translate(1075 633)" fill="#1976d2" fontSize="600"
                      fontFamily="Heebo-Medium, Heebo" fontWeight="500">
                    <tspan x="0" y="0">inpriton</tspan>
                </text>
            </g>
        </svg>
    )
}

export default InpritonLogo