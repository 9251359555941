import {configureStore} from '@reduxjs/toolkit'
import messagesSlice from "./slices/messagesSlice";
import filesSlice from "./slices/filesSlice";
import signatureSlice from "./slices/signatureSlice";

const store = configureStore({
    reducer: {
        messages: messagesSlice,
        files: filesSlice,
        signature: signatureSlice,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
