import React from "react";

export default class MaterialIcon implements IconInterface
{
    static STYLE_SHARP: string = 'sharp'
    static STYLE_OUTLINED: string = 'outlined'

    name: string
    style: string

    constructor(name: string, style: string = MaterialIcon.STYLE_SHARP) {
        this.name = name
        this.style = style
    }

    get(): React.ReactNode {
        return (
            <span className={`material-symbols-${this.style}`}>
                { this.name }
            </span>
        )
    }
}